body{
    background-color: white;
    color: black;
    }
    
    h1 {
    color: red;
    }
    
    h6{
    color: red;
    text-decoration: underline;
    }
    
    