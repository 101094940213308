
.tree__folder--collapsible{
  transition: 0.3s ease-in-out;
}

.folder{
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  margin-top: 0.4vh;
}
.folderDiv{
  font-weight: bold;
  padding-left: 0.5vw;
  
}
.folderDiv .fileDiv{
  padding-left:1.5vw;
}
.verticalLine{
  /* display: none; */
  position: relative;
  /* margin-left: 3px; */
  
}

.verticalLine:hover:before {  
  content: "";
  display: block;
  position: absolute;
  top: -2px; 
  bottom:-2px;
  width: 0;
  height: 100%;
  border-left: 1px solid gray;
  z-index: -1;
}
.fileDeleteDiv>svg{
cursor:pointer;
margin-left: 10px;
transform: scale(1);
/* transition: 0.2s; */
}
