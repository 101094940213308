html, body {
  height: 100%;
  margin: 0px;
  /* border:3px solid blue; */
}

#root {
  height: 100%;
}

.App {
  text-align: center;
  height: 100%;
  /* border:1px solid black; */

}

.main {
  width: 100%;
  height: 100%;
}

.view-port {
  display: flex;
  height: 100%;
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.floating-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 20px;
  top: 10px;
  border: 1px solid darkgray;
}
#filename li{
  list-style-type:none;
}
