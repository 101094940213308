
  .deleteButton{
    cursor: pointer;
  }
  .TypoGraphy{
    font-size: 1rem;
  }
  .extention{
    padding-left:1%;
    padding-top:2%;
    padding-bottom:2%;

  }
  .footer{
    background-color: lightblue;
    width:100%;
    justify-content:center;
    padding:12px;
    position:'relative'
  }
  /* @media only screen and (min-width:1200px){
    .footer{
    position:absolute;
    bottom:0;
    }
  } */
  
  @media only screen and (max-width: 500px) {
    body {
      font-size: 10px;
    }
    .fileListItem{
      font-size: 10px;
    }
  }
.browseUploadGrid{
  height:15vh
}
