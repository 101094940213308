.filediv:active .fileDeleteDiv,.filediv:hover .fileDeleteDiv{
    display:inline-block;
    padding-top: 3px;
}
.filediv .fileDeleteDiv{
    display:none;   
    /* display:inline-block; */

}

.filediv{
    margin-top: 0.4vh;
    flex-wrap: nowrap; 
    display: flex;
   align-items: center;
  font-weight: normal;
  padding-left: 2vw;
}
.loader {
    margin-top:3px;
    margin-left:5px;
    border: 3px solid black;
    border-radius: 50%;
    border-top: 3px solid #ffdab9;
    width: 12px;
    height: 12px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }